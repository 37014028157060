$(document).ready(function() {
	$(document).foundation();
});


$(function(){
	$('.navbar-toggle, nav.header').click(function(){
		$('.navbar-toggle').toggleClass('navbar-on');
		$('nav.header').fadeToggle();
		$('nav.header').toggleClass('nav-hide');
	});
});

$(function() {
	$("img.lazy").lazyload({
		effect : "fadeIn",
		container: $("#container")
	});
});

/* Delete */
$('.confirm').on('click', function(e) {
	var $form = $(this).closest('form');
	e.preventDefault();

	$('#deleteModal').modal({backdrop: 'static', keyboard: false})
		.one('click', '#delete', function (e) {
			$form.trigger('submit');
		});
});

/* Notice */
$(document).ready(function(){
	setTimeout(function(){
		$('#notice').slideUp();
	}, 3000);
});

/* Project Grid */
$(document).ready(function(){
	var height = (parseInt($(window).height()) - parseInt(200)) / parseInt(3);

	if (parseInt(height) < parseInt(225)) {
		$('.projectGrid__project--image').each(function () {
			$(this).height(height);
		});
	}
});
